import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import '@/assets/css/common.less'
import { getAction , postAction, downloadFile } from './api'
import interFace from "./interface/interface";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import MetaInfo from 'vue-meta-info'



Vue.use(ElementUI);

Vue.use(MetaInfo)

Vue.prototype.$http = { getAction , postAction, downloadFile }

Vue.prototype.$interface = interFace;

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
