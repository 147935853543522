import  Vue from 'vue'
import VueRouter from 'vue-router'
import PortalPage from '@/views/PortalPage/PortalPage.vue'
// import CGHC from '@/views/CGHC'
// import CGHCDetail from '@/views/CGHC/cghcDetail'
// import CGHCList from '@/views/CGHC/cghcList'
// import  qualityInspection from '@/views/qualityInspection/index.vue'
// import SatisfactionSurveys from '@/views/qualityInspection/component/SatisfactionSurveys.vue'
// import checkPage from '@/views/qualityInspection/component/checkPage.vue'
// import body from '@/views/qualityInspection/component/body'
// import moreInfo from '@/views/qualityInspection/component/moreInfo'
// import reportDetails from '@/views/qualityInspection/component/reportDetails'
// import downLoad from '@/views/qualityInspection/component/download'
// import articlePage from '@/views/qualityInspection/component/articlePage'
// import centerInfo from '@/views/qualityInspection/component/centerInfo'
// import pdf from '@/component/pdfCom'
//解决路由冗余问题（重写push|replace方法）
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history', //seo优化 router中必须是history模式。
    routes:[
        {
            path: '/',
            redirect: '/PortalPage',

        },
        {
          path: '/PortalPage',
          name: 'PortalPage',
          component: PortalPage
        },
        //CGHC
        // {
        //   path: '/CGHC',
        //   name: 'CGHC',
        //   component: CGHC
        // },
        // {
        //     path: '/CGHC-list',
        //     name: 'CGHCList',
        //     component: CGHCList
        // },
        // {
        //     path: '/CGHC-detail',
        //     name: 'CGHCDetail',
        //     component: CGHCDetail
        // },
        //   //GGAC
        // {
        //     path: '/qualityInspection',
        //     name:'qualityInspection',
        //     component: qualityInspection,
        //     redirect: '/qualityInspection/body',
        //     children:[
        //       {
        //          path:'/qualityInspection/body',
        //          name:'body',
        //          component: body
        //       },
        //       {
        //         path:'/qualityInspection/SatisfactionSurveys',
        //         name:'SatisfactionSurveys',
        //         component: SatisfactionSurveys
        //       },
        //       {
        //         path: '/qualityInspection/moreInfo',
        //         name:'moreInfo',
        //         component: moreInfo
        //       },
        //
        //       {
        //         path:'/qualityInspection/checkPage',
        //         name:'checkPage',
        //         component: checkPage
        //         },
        //         {
        //           path: '/qualityInspection/download',
        //           name: 'downLoad',
        //           component: downLoad
        //
        //         },
        //         {
        //           path: '/qualityInspection/articlePage',
        //           name: 'articlePage',
        //           component: articlePage
        //         },
        //         {
        //           path: '/qualityInspection/centerInfo',
        //           name: 'centerInfo',
        //           component: centerInfo
        //         }
        //
        //     ]
        // },
        // {
        //   path: '/reportDetails',
        //   name: 'reportDetails',
        //   component: reportDetails
        // },
        // {
        //     path: '/pdf',
        //     name: 'pdf',
        //     component: pdf
        // }

    ],

    scrollBehavior(){
        return {y:0,x:0}
    }
})

export default router
