import axios from 'axios'
import nProgress from 'nprogress'
import "nprogress/nprogress.css"
const requests=axios.create({
    baseURL:`${process.env.VUE_APP_BASE_API}${process.env.VUE_APP_BACKEND_PORT}`,
    //设置请求超时的时间
    timeout:5000,
})
requests.interceptors.request.use((config)=>{
    //进度条开始动
    nProgress.start()
    return config
})
//响应拦截器
requests.interceptors.response.use((resolve) => {
   //进度条结束
   nProgress.done()
   return resolve.data
}, (reject) => {
     return reject.message
})
export default requests
