<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  watch: {
    $route:{
      immediate:true,
      handler(){
        let routePath=this.$route.path // 通过路由判断查询的网址
        let siteType=routePath.indexOf('qualityInspection')>-1?'国家燃气用具质量监督检验中心':'中国燃气采暖热水炉官网'
        if(routePath.indexOf("reportDetails")>-1){
          siteType='国家燃气用具质量监督检验中心'
        }
        this.$http.getAction(this.$interface.cgac.visited,
                {
                  siteType:siteType
                }
        )
      }
    }
  },
  mounted () {
    this.initialDpi()
    this.watchResize()
  },
  beforeDestroy() {
    this.removeWatch()
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => (this.isRouterAlive = true))
    },
    /**
     * 初始化，根据屏幕分辨率,计算rem单位制
     */
    initialDpi(){
      let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
      let htmlDom = document.getElementsByTagName('html')[0]
      htmlDom.style.fontSize = 10 / 1920 * htmlWidth + 'px';
    },

    /**
     * 添加监听事件，实时处理分辨率问题
     */
    watchResize(){
      // 表示在1920px分辨率下，根元素fontSize为10px，则公式为1rem = 10px。根据1920设计稿布局，即可做到自适应
      window.addEventListener('resize',()=>{
        let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
        let htmlDom = document.getElementsByTagName('html')[0]
        htmlDom.style.fontSize = 10 / 1920 * htmlWidth + 'px';
      })
    },
    /**
     * 移除对应监听事件
     */
    removeWatch(){
      window.removeEventListener('resize',()=>{
        let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
        let htmlDom = document.getElementsByTagName('html')[0]
        htmlDom.style.fontSize = 10 / 1920 * htmlWidth + 'px';
      })
    },

  }
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

</style>
