const interFace={
  //cgac
  cgac:{
    // 问卷调查
    satisfactionSurveys: '/websiteQuestionnaires/add',
    //友情链接
    friendLinks: '/websiteLinks/queryBySiteType',
    //检验报告
    report: '/websiteInspectionReport/pageCondition',
    //检验报告分页查询 || 关键字查询
    reportByKeyword:'/websiteInspectionReport/pageCondition',

    //热搜关键词
    hotKeyword: '/websiteHotSearch/queryKeywords',
    //搜索 ||'更多'文章列表查询
    search: '/websiteEssay/page',
    searchKey: '/websiteEssay/getEssayByTitle',
    searchId:'/websiteEssay/queryById',
    //查询+1
    searchTimes: '/websiteHotSearch/add',
    //下载预览
    fileDown:'/websiteDownloadInfo/getBySite',
    // fileDown:'/websiteDownloadInfo/page',
    //获取轮播图id
    getBanner:'/websiteCyclePicture/getBanner',
    //根据id找图
    getPicById:'/websiteCyclePicture/view/',
    //下载大列表
    downLodaList: '/websiteDownloadInfo/page',
    //下载文件
    downLoad:'/websiteEssay/download/',
    //下载文件的详细信息
    fileData:'/websiteDownloadInfo/queryById',
    //预览pdf 图片
    viewPDF:'/websiteCyclePicture/view/',
      //访问记录
    visited: '/websiteVisitLog/visit',
    //获取联系我们
    getUs:'/websiteContactUs/page'
  },

  //cghc
  cghc:{
    // 学会动态左侧推荐图片内容 首页的每日公司和分会介绍
    RecommendEssay:"/websiteEssay/getRecommendEssayByRecommend",
    // 学会动态左侧推荐图片内容列表
    RecommendEssayList:"/websiteEssay/getPartInfoByType",
    // 文章列表
    EssayList:"/websiteEssay/getSimpleEssayByType",
    // 文字附件下载
    EssayDownloadFile:"/websiteEssay/download/",
    // 图片预览
    ViewImg:"/websiteCyclePicture/view/",
    // 人物列表
    PerList:"/websitePersona/page",
    // 人物通过id查询
    PerInfo:"/websitePersona/queryById",
    // 下载列表
    DownloadList:"/websiteDownloadInfo/page",
    // 下载列表根据title查询
    DownloadListByTitle:"/websiteDownloadInfo/queryByTitle",
    // 通过id查询文件信息
    DownloadInfo:"/websiteDownloadInfo/queryById",
    // 通过id下载
    DownloadById:"/websiteDownloadInfo/download/",
    // 友情链接列表
    linksList:"/websiteLinks/queryBySiteType",
    // 轮播图列表
    BannerList:"/websiteCyclePicture/getBanner",
    // 通过id查询文章详情
    DetailById:"/websiteEssay/queryById",
    // 新闻列表
    newsList:"/websiteEssay/getEssayByTitle",
    // 联系我们
    ContentList:"/websiteContactUs/page",
  }
}
export default interFace;
